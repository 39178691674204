export type StateOption = { id: string | null; name: string };

const usStates: StateOption[] = [
    { id: "al", name: "Alabama" },
    { id: "ak", name: "Alaska" },
    { id: "az", name: "Arizona" },
    { id: "ar", name: "Arkansas" },
    { id: "ca", name: "California" },
    { id: "co", name: "Colorado" },
    { id: "ct", name: "Connecticut" },
    { id: "de", name: "Delaware" },
    { id: "dc", name: "District of Columbia" },
    { id: "fl", name: "Florida" },
    { id: "ga", name: "Georgia" },
    { id: "hi", name: "Hawaii" },
    { id: "id", name: "Idaho" },
    { id: "il", name: "Illinois" },
    { id: "in", name: "Indiana" },
    { id: "ia", name: "Iowa" },
    { id: "ks", name: "Kansas" },
    { id: "ky", name: "Kentucky" },
    { id: "la", name: "Louisiana" },
    { id: "me", name: "Maine" },
    { id: "md", name: "Maryland" },
    { id: "ma", name: "Massachusetts" },
    { id: "mi", name: "Michigan" },
    { id: "mn", name: "Minnesota" },
    { id: "ms", name: "Mississippi" },
    { id: "mo", name: "Missouri" },
    { id: "mt", name: "Montana" },
    { id: "ne", name: "Nebraska" },
    { id: "nv", name: "Nevada" },
    { id: "nh", name: "New Hampshire" },
    { id: "nj", name: "New Jersey" },
    { id: "nm", name: "New Mexico" },
    { id: "ny", name: "New York" },
    { id: "nc", name: "North Carolina" },
    { id: "nd", name: "North Dakota" },
    { id: "oh", name: "Ohio" },
    { id: "ok", name: "Oklahoma" },
    { id: "or", name: "Oregon" },
    { id: "pa", name: "Pennsylvania" },
    { id: "ri", name: "Rhode Island" },
    { id: "sc", name: "South Carolina" },
    { id: "sd", name: "South Dakota" },
    { id: "tn", name: "Tennessee" },
    { id: "tx", name: "Texas" },
    { id: "ut", name: "Utah" },
    { id: "vt", name: "Vermont" },
    { id: "va", name: "Virginia" },
    { id: "wa", name: "Washington" },
    { id: "wv", name: "West Virginia" },
    { id: "wi", name: "Wisconsin" },
    { id: "wy", name: "Wyoming" },
];

export const nullStateOption: StateOption = { id: null, name: "Nationwide" };

export const multiStateOptions: StateOption[] = [nullStateOption, { id: "us", name: "Federal" }, ...usStates];
export const singleStateOptions: StateOption[] = [
    nullStateOption,
    { id: "us", name: "Federal" },
    ...usStates,
];


export function stateFromId(id: string | null | undefined): StateOption {
    if (!id) return nullStateOption

    return singleStateOptions.find((state) => state.id === id) ?? nullStateOption;
}
