import payload_plugin_e0k4tW9bdI from "/usr/src/app/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import modals_bidRKewKK5 from "/usr/src/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/usr/src/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/usr/src/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/usr/src/app/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/usr/src/app/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_client_LcKgStRyi6 from "/usr/src/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_mik7N71GmK from "/usr/src/app/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/usr/src/app/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_xgxXjNHXgE from "/usr/src/app/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/usr/src/app/.nuxt/vuefire-plugin.mjs";
import plugin_eTVJQYlCmx from "/usr/src/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_ClOs2HRIGm from "/usr/src/app/src/plugins/analytics.client.ts";
import apollo_1WKTIgI8SC from "/usr/src/app/src/plugins/apollo.js";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_client_LcKgStRyi6,
  plugin_client_mik7N71GmK,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_xgxXjNHXgE,
  vuefire_plugin_8cq4FnzXKb,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  analytics_client_ClOs2HRIGm,
  apollo_1WKTIgI8SC
]