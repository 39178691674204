import { VueFireAuthWithDependencies, _VueFireAuthKey } from 'vuefire'
import { defineNuxtPlugin } from '#imports'
import { inject } from 'vue'
import {
  
    
      indexedDBLocalPersistence,
    
      browserLocalPersistence,
    
  

  
    browserPopupRedirectResolver,
  

  
    debugErrorMap,
  
} from 'firebase/auth'

/**
 * Setups VueFireAuth for the client. This version creates some listeners that shouldn't be set on server.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const firebaseApp = nuxtApp.$firebaseApp

  VueFireAuthWithDependencies({
    initialUser: nuxtApp.payload.vuefireUser,
    dependencies: {
      
        errorMap: debugErrorMap,
      

      
        persistence: [
        
          indexedDBLocalPersistence,
        
          browserLocalPersistence,
        
        ],
      

      
        popupRedirectResolver: browserPopupRedirectResolver,
      
    },
  })(firebaseApp, nuxtApp.vueApp)

  return {
    provide: {
      firebaseAuth: nuxtApp.vueApp.runWithContext(() => inject(_VueFireAuthKey))
    },
  }
})
